import React, { PureComponent }from "react"
import Layout2 from "../components/layout2"
import Fade from 'react-reveal/Fade'
import Counter from './../elements/counter.js'
import LineChart from './../elements/linechart.js'
import Signature from './../elements/signature.js'
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Img from 'gatsby-image'
import Applause from './../elements/applause.js'
import Button from './../elements/button.js'
import EmailSignup from './../components/emailsignup.js'
import Footer from "./../components/footer.js"
import ReactVivus from 'react-vivus';

export default class Stats extends PureComponent {

  constructor(){
    super();
    this.state = {
        images:[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,
          100, 101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,
          200, 201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231,232,233,234,235,236,237,238,239,240,241,242,243,244,245,246,247,248,249,250,251,252,253,254,255,256,257,258,259,260,261,262,263,264,265,266,267,268,269,270,271,272,273,274,275,276,277,278,279,280,281,282,283,284,285,286,287,288,289,290,291,292,293,294,295,296,297,298,299,
          300,301,302,303,304,305,306,307,308,309,310,311,312,313,314,315,316,317,318,319,320,321,322,323,324,325,326,327,328,329,330,331,332,333,334,335,336,337,338,339,340,341,342,343,344,345,346,347,348,349,350,351,352,353,354,355,356,357,358,359,360,361,362,363,364,365,366,367,368,369,370,371,372,373,374,375,376,377,378,379,380,381,382,383,384,385,386,387,388,389,390,391,392,393,394,395,396,397,398,399,
          400,401,402,403,404,405,406,407,408,409,410,411,412,413,414,415,416,417,418,419,420,421,422,423,424,425,426,427,428,429,430,431,432,433,434,435,436,437,438,439,440,441,442,443,444,445,446,447,448,449,450,451,452,453,454,455,456,457,458,459,460,461,462,463,464,465,466,467,468,469,470,471,472,473,474,475,476,477,478,479,480,481,482,483,484,485,486,487,488,489,490,491,492,493,494,495,496,497,498,499],
    }
}

  render() {
    
    return (
<Layout2>
<StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "img/stats/journeys_preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Brett Bejcek: Journeys">
  <meta name="description" content="Life is always in motion, but that doesn't mean you can't take a step back and reflect at the paths you're carving for yourself in this world." />
  <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Brett Bejcek: Journeys" />
  <meta property="og:description" content="Life is always in motion, but that doesn't mean you can't take a step back and reflect at the paths you're carving for yourself in this world." />
  <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="Brett Bejcek: Journeys" />
  <meta name="twitter:description" content="Life is always in motion, but that doesn't mean you can't take a step back and reflect at the paths you're carving for yourself in this world." />
  <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>
<div css = {{marginLeft: `auto`, marginRight:`auto`, textAlign: `center`, maxWidth:`700px`, padding:`5%`}}>
<Fade>
<h1 css = {{left:`12rem`,right:`12rem`, '@media (max-width: 900px)': {left:`1rem`, right:`1rem`}}}> Journeys </h1>
<p css = {{ left:`12rem`,right:`12rem`, '@media (max-width: 900px)': {left:`1rem`, right:`1rem`}}}>We're in constant motion: going to the grocery store, heading to coffee with a friend, or desperately running to the Clinton-Washington subway stop because the C is the worst subway line in all of NYC and the next one isn't coming for twenty minutes. I digress.<br/> <br/>Each one of these 500 paths below represents a journey I made. Every time I make a new journey, it gets added and an old one is kicked out. Life is always in motion, but that doesn't mean you can't take a step back and reflect at the paths you're carving for yourself in this world. <br/><br/>Scroll to enjoy :)</p>
</Fade>
<div css={{display:`grid`, gridTemplateColumns:`10% 10% 10% 10% 10% 10% 10% 10% 10% 10%`}}>
{this.state.images.map((i, index) => {
        return (
          <div>
          <ReactVivus css={{padding:`10%`}} id = {"a" + i} option={{file: "https://www.googleapis.com/download/storage/v1/b/brett-bejcek-site-data/o/" + i + ".svg?alt=media", type: 'sync',duration: 30,start: 'inViewport'}}/>
          </div>
        )
      })}
</div>

<Fade>
<div css = {{marginBottom:`5rem`, marginTop:`5rem`, width:`30%`, marginLeft:`auto`, marginRight:`auto`,'@media (max-width: 767px)': {width:`50%`}}}>
</div>
</Fade>
<Fade>
<br/>
<br/>
<Button link = "/" cta = "back to home"/>
<br/>
<br/>
</Fade>

</div>
<div css={{maxWidth:`700px`, marginLeft:`auto`, marginRight:`auto`}}>
<EmailSignup/>
<Footer/>
</div>
</Layout2>);}
}
